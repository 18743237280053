import { omit } from 'lodash';
import { stringify } from 'querystring';
import { useInfiniteQuery, useQuery } from 'react-query';
import { Bonus, BonusResponse } from '../../types/Bonus';
import { CasinoTx } from '../../types/Casino';
import { CategoryTx } from '../../types/Category';
import { ListResponse } from '../../types/common';
import { JockerApi } from '../api';

const normalizeBonus = (bonus: BonusResponse): Bonus => ({
  ...bonus,
  casinoReview: bonus.casinoReview
    ? {
        ...bonus.casinoReview,
        translations: bonus.casinoReview?.translations.reduce(
          (acc, translation) => ({
            ...acc,
            [translation.languageCode]: translation,
          }),
          {} as Record<string, CasinoTx>,
        ),
      }
    : ({} as any),
  categories: bonus.categories.map((category) => ({
    ...category,
    translations: category.translations.reduce(
      (acc, translation) => ({
        ...acc,
        [translation.languageCode]: translation,
      }),
      {} as Record<string, CategoryTx>,
    ),
  })),
  translations: bonus.translations.reduce(
    (acc, translation) => ({
      ...acc,
      [translation.languageCode]: translation,
    }),
    {},
  ),
});

const normalizeBonusList = (list: BonusResponse[]): Bonus[] =>
  list.map((item) => normalizeBonus(item));

const defaultPaginationParams = {
  perPage: 10,
};

const fetchBonusesList = async (
  { isLoadMore, ...props }: Record<string, string | string[] | boolean>,
  pageParam: number,
) => {
  const params = {
    ...defaultPaginationParams,
    ...props,
    currentPage: isLoadMore ? pageParam + 1 : props.currentPage,
  };
  const { data } = await JockerApi.get<ListResponse<BonusResponse>>(
    `bonuses/public-list?${stringify(params)}`,
  );
  const normalizedCasinos = normalizeBonusList(data.data);
  return {
    ...data,
    data: normalizedCasinos,
  } as ListResponse<Bonus>;
};

const fetchCasinoById = async (id: number) => {
  const { data } = await JockerApi.get<BonusResponse>(`casinos?id=${id}`);
  return normalizeBonus(data);
};

export const useBonusListInfinity = (
  params: Record<string, string | string[] | boolean>,
  options?: any,
) =>
  useInfiniteQuery<ListResponse<Bonus>, Error>(
    ['bonus-list-infinity', omit(params, 'isLoadMore')],
    ({ pageParam = 0 }) => fetchBonusesList(params, pageParam),
    {
      ...options,
      staleTime: Infinity,
      getPreviousPageParam: (firstPage) =>
        firstPage.pagination.currentPage ?? undefined,
      getNextPageParam: (lastPage) =>
        lastPage.pagination.currentPage ?? undefined,
    },
  );

export const useBonusList = (
  params: Record<string, string | string[] | boolean>,
  options?: any,
) =>
  useQuery<ListResponse<Bonus>, Error, ListResponse<Bonus>>(
    ['bonus-list', params],
    ({ pageParam = 0 }) => fetchBonusesList(params, pageParam),
    {
      ...options,
    },
  );

// SSR - prefetch

export const prefetchBonuses = async (
  queryClient,
  params: Record<string, string | string[] | boolean>,
) => {
  await queryClient.prefetchQuery({
    queryKey: ['bonus-list', params],
    queryFn: () => fetchBonusesList(params, 0),
  });
};

export const prefetchBonusesInifnity = async (
  queryClient,
  params: Record<string, string | string[] | boolean>,
) => {
  await queryClient.prefetchInfiniteQuery({
    queryKey: ['bonus-list-infinity', omit(params, 'isLoadMore')],
    queryFn: () => fetchBonusesList(params, 0),
  });
};
