import classNames from 'classnames';
import { get, isEmpty } from 'lodash';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';
import { Bonus, BonusTx } from '../../types/Bonus';
import { CasinoTx } from '../../types/Casino';
import BottomIcon from '../../assets/icons/bottom_icon.svg';
import TopIcon from '../../assets/icons/top_icon.svg';
import styles from './Bonuses.module.css';
import { MyImage } from '../MyImage';
import { useMemo, useState } from 'react';
import { BONUSES_FIELDS } from './consts';
import { useConfig } from '../../config/queries';
import { getCookie } from '../../utils/cookies';

interface ICardInfo {
  className?: string;
  withoutCasinoInfo?: boolean;
  hideReviewBtn?: boolean;
}

const BonusesCard = ({
  hideReviewBtn,
  withoutCasinoInfo,
  className,
  ...bonus
}: Bonus & ICardInfo) => {
  const { locale = 'en' } = useRouter();
  const userCountry = getCookie('user_country');
  const { data } = useConfig();
  const [readMore, changeReadMore] = useState(false);
  const [firstFiveValues, nextValues] = useMemo(() => {
    const firstFive = BONUSES_FIELDS.slice(0, 5);
    const restOfArray = BONUSES_FIELDS.slice(5);
    return [firstFive, restOfArray];
  }, []);
  const bonusLocale: BonusTx = get(
    bonus,
    ['translations', locale],
    bonus.translations.en,
  );
  const casinoLocale: CasinoTx = get(bonus, [
    'casinoReview',
    'translations',
    locale,
  ]);
  const userCurrency = get(data, ['country', userCountry], 'USD');
  const currencySymbol = get(data, ['currency', 'USD'], '$');
  const handleChangeReadMore = () => {
    changeReadMore((_v) => !_v);
  };

  return (
    <div className={classNames(styles.bonusWrapper, className)}>
      {!withoutCasinoInfo && !!casinoLocale && (
        <div className={styles.bonusHeader}>
          {casinoLocale.logotype ? (
            <MyImage
              src={casinoLocale.logotype}
              width={24}
              height={24}
              alt={casinoLocale?.name || 'Casino image'}
            />
          ) : (
            <div className={styles.imagePlaceholder} />
          )}
          <span>{casinoLocale?.name}</span>
        </div>
      )}
      <div className={styles.bonusName}>{bonusLocale.name}</div>
      {!isEmpty(bonus.categories) && (
        <div className={styles.labels}>
          {bonus.categories.map((category) => {
            const categoryEn = get(category, ['translations', 'en']);

            const categoryLocale = get(
              category,
              ['translations', locale],
              categoryEn,
            );
            return (
              <span
                key={category.id}
                style={{ background: category.color }}
                className={classNames(styles.best, styles.bonusCategory)}
              >
                {category.img && (
                  <MyImage
                    visibleByDefault={true}
                    src={category.img}
                    height={14}
                    width={14}
                    alt={categoryLocale.name || 'Category image'}
                  />
                )}

                {categoryLocale.name}
              </span>
            );
          })}
        </div>
      )}
      <div className={styles.options}>
        {firstFiveValues.map((option) => {
          const isCurrencyValue = typeof bonusLocale[option.field] === 'object';
          const value = isCurrencyValue
            ? get(bonusLocale, [option.field, userCurrency, 'value'])
            : bonusLocale[option.field];
          return (
            <div className={styles.optionRow} key={option.field}>
              <div className={styles.optionCol}>{option.lable}</div>
              <div>
                {value || '--'} {isCurrencyValue && value && userCurrency}
              </div>
            </div>
          );
        })}
        {readMore &&
          nextValues.map((option) => {
            const isCurrencyValue =
              typeof bonusLocale[option.field] === 'object';

            const value = isCurrencyValue
              ? get(bonusLocale, [option.field, userCurrency, 'value'])
              : bonusLocale[option.field];

            return (
              <div className={styles.optionRow} key={option.field}>
                <div className={styles.optionCol}>{option.lable}</div>
                <div>
                  {value || '--'} {isCurrencyValue && value && userCurrency}
                </div>
              </div>
            );
          })}
        <div className={styles.more} onClick={handleChangeReadMore}>
          <span>
            {readMore ? (
              <FormattedMessage defaultMessage="Read less" />
            ) : (
              <FormattedMessage defaultMessage="Read more" />
            )}
          </span>
          {readMore ? <TopIcon /> : <BottomIcon />}
        </div>
      </div>
      {casinoLocale && (
        <div className={styles.buttons}>
          {!hideReviewBtn && (
            <Link href={`/casinos/${casinoLocale.slug}`}>
              <a className={styles.review}>
                <FormattedMessage defaultMessage="Casino info" />
              </a>
            </Link>
          )}
          <Link href={casinoLocale.url || ''}>
            <a
              className={classNames(
                styles.bonus,
                hideReviewBtn && styles.fullWidth,
              )}
            >
              <FormattedMessage defaultMessage="Get Bonus" />
            </a>
          </Link>
        </div>
      )}
    </div>
  );
};

export { BonusesCard };
