import { FormattedMessage } from 'react-intl';

export const BONUSES_FIELDS = [
  {
    field: 'wager',
    lable: <FormattedMessage defaultMessage="Wagger" />,
  },
  {
    field: 'maxBet',
    lable: <FormattedMessage defaultMessage="Max Bet" />,
  },
  {
    field: 'minDeposit',
    lable: <FormattedMessage defaultMessage="Minimum deposit" />,
  },
  {
    field: 'maxBonusAmount',
    lable: <FormattedMessage defaultMessage="Max bonus amount" />,
  },
  {
    field: 'bonusValue',
    lable: <FormattedMessage defaultMessage="Bonus value %" />,
  },
  {
    field: 'numberOfFs',
    lable: <FormattedMessage defaultMessage="Number of FS" />,
  },
  {
    field: 'bonusFixBet',
    lable: <FormattedMessage defaultMessage="Bonus fix bet" />,
  },
  {
    field: 'maxCashout',
    lable: <FormattedMessage defaultMessage="Max cashout" />,
  },
];
