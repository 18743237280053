import { get } from 'lodash';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';
import { Bonus } from '../../types/Bonus';
import MyImage from '../MyImage';
import styles from './BonusesSmall.module.css';

const BonusesCardSmall = (bonus: Bonus) => {
  const { locale } = useRouter();
  const bonusTx = get(bonus, ['translations', locale]);
  const casinoTx = get(bonus, ['casinoReview', 'translations', locale]);

  return (
    <div className={styles.bonusWrapper}>
      <div className={styles.bonusHeader}>
        {casinoTx?.logotype ? (
          <MyImage
            src={casinoTx.logotype}
            width={20}
            height={20}
            alt={casinoTx?.name || 'Casino image'}
            className={styles.casinoImage}
          />
        ) : (
          <div className={styles.imagePlaceholder} />
        )}
        <span>{casinoTx?.name}</span>
      </div>
      <p>{bonusTx.name}</p>
      <Link href={casinoTx?.url || ''}>
        <a>
          <FormattedMessage defaultMessage="Get Bonus" />
        </a>
      </Link>
    </div>
  );
};

export { BonusesCardSmall };
